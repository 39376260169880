import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export default function Footer() {
  const data = useStaticQuery(graphql`
    query {
      ig: file(relativePath: { eq: "social/SocialIcons-IG.png" }) {
        childImageSharp {
          fixed(quality: 100, width: 48) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      fb: file(relativePath: { eq: "social/SocialIcons-FB.png" }) {
        childImageSharp {
          fixed(quality: 100, width: 48) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <footer>
      <section className="SocialIcons">
        <ul className="socialImages">
          <li>
            <a href="https://www.instagram.com/crtvbox.co">
              <Img
                style={{ maxWidth: "48px", margin: "0 auto" }}
                fixed={data.ig.childImageSharp.fixed}
              />
            </a>
          </li>

          <li>
            <a href="https://www.facebook.com/crtvbox.co">
              <Img
                style={{ maxWidth: "48px", margin: "0 auto" }}
                fixed={data.fb.childImageSharp.fixed}
              />
            </a>
          </li>
        </ul>
      </section>
      <section className="copyright">
        <p>All Rights Reserved © 2021 CreativeBox</p>
      </section>
    </footer>
  )
}
