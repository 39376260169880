import React from "react"
import { Helmet } from "react-helmet"
import logo from "../../static/CRTVboxLogo.png"

console.log(logo)

export default function Header() {
  return (
    <div className="headerContainer">
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/vlh7ocj.css" />
      </Helmet>
      <header>
        <div className="logo-container">
          <a href="/" className="logo">
            <img src={logo} alt="Creative Box Logo" />
          </a>
        </div>
        <nav>
          <ul>
            <li>
              <a href="/work">Work</a>
            </li>
            <li>
              <a href="/pricing">Pricing</a>
            </li>
            <li className="cta">
              <a href="/book">Book Now</a>
            </li>
          </ul>
        </nav>
      </header>
    </div>
  )
}
