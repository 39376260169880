import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Img from "gatsby-image"

export default function Header({ title, subtitle }) {
  const data = useStaticQuery(graphql`
    query {
      file: file(relativePath: { eq: "assets/WEBBackGround-02.png" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div className="heroContainer">
      <Img fluid={data.file.childImageSharp.fluid} />
      <div className="heroTextWrapper">
        <h1 className="herotext">{title}</h1>
        <h2 className="subtitle">{subtitle}</h2>
      </div>
    </div>
  )
}
