import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import HeroTitle from "../components/herotitle"

export default function Layout({ children, title, subtitle }) {
  return (
    <div style={{ margin: `0 auto`, padding: `0`, display: "block" }}>
      <Header />
      <HeroTitle title={title} subtitle={subtitle} />
      {children}
      <Footer />
    </div>
  )
}
